import type { FC } from 'react';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import type { FlagsStateContainer } from '@confluence/flags';

const i18n = defineMessages({
	sessionExpirationTitle: {
		id: 'super-admin.session.expiration.title',
		defaultMessage: 'Access to restricted pages has ended',
		description: 'Title for Super admin session expiration flag',
	},
	sessionExpirationDescription: {
		id: 'super-admin.session.expiration.description',
		defaultMessage:
			'You’ve been inactive for a while. To keep things secure, we’ve ended your access to restricted pages.',
		description: 'Description for Super admin session expiration flag',
	},
});

export type SuperAdminExpirationFlagProps = {
	flags: FlagsStateContainer;
};

/**
 * This component is used to show the flag after the Admin key session was expired by the timeout.
 */
export const SuperAdminExpirationFlag: FC<SuperAdminExpirationFlagProps> = ({ flags }) => {
	const intl = useIntl();

	useEffect(() => {
		const localStorageValue = localStorage.getAndRemoveItem(keys.SUPERADMIN_SESSION_EXPIRED);
		if (localStorageValue) {
			void flags.showInfoFlag({
				title: intl.formatMessage(i18n.sessionExpirationTitle),
				description: intl.formatMessage(i18n.sessionExpirationDescription),
			});
		}
	}, [flags, intl]);

	return null;
};
