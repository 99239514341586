class SafeString {
	constructor(value) {
		this.value = value;
		this.valueOf = this.toString = this.toJSON = () => this.value;
	}
}

export const dangerouslyCreateSafeString = (value) => new SafeString(value);

export const isSafeString = (value) => value instanceof SafeString;

// This is the preferred way to create a SafeString - by matching a value against a static list of allowedStrings
// Use dangerouslyCreateSafeString if you have a dynamic string that you are really sure is privacy safe
export const markAsSafe =
	(...allowedStrings) =>
	(value) =>
		allowedStrings.includes(value) ? Object.freeze(new SafeString(value)) : value;
