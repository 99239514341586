import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import withAnalyticsEvents from '@atlaskit/analytics-next/withAnalyticsEvents';

import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';

import { DisableSuperAdminMutation } from '../disable-super-admin/DisableSuperAdminMutationComponent';

import { SuperAdminExpirationTimer } from './SuperAdminExpirationTimer';
import { addSecondsToDate } from './timeoutUtils';
import { SuperAdminExpirationFlag } from './SuperAdminExpirationFlag';

class SuperAdminExpirationComponent extends Component {
	static propTypes = {
		createAnalyticsEvent: PropTypes.func.isRequired,
	};

	fireAdminKeyExpiredAnalyticsEvent = () => {
		this.props
			.createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'superAdminExpiration',
					action: 'expired',
					actionSubject: 'adminKeySession',
				},
			})
			.fire();
	};

	deleteStoredRestrictedPage = () => localStorage.removeItem(keys.RESTRICTED_PAGE_CONFIRMED);

	fireAdminKeyExpiryFailedAnalyticsEvent = () =>
		this.props
			.createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'failed',
					actionSubject: 'exitAdminKeySession',
					source: 'superAdminExpiration',
				},
			})
			.fire();

	setAKSessionExpired = () =>
		localStorage.setItem(
			keys.SUPERADMIN_SESSION_EXPIRED,
			true,
			addSecondsToDate(new Date(), 10).getTime(),
		);

	render = () => {
		return (
			<DisableSuperAdminMutation
				handleMutationCompleteCallback={() => {
					this.fireAdminKeyExpiredAnalyticsEvent();
					this.deleteStoredRestrictedPage();
					this.setAKSessionExpired();
				}}
				handleMutationErrorCallback={() => {
					this.fireAdminKeyExpiryFailedAnalyticsEvent();
					return Promise.resolve();
				}}
			>
				{(disableSuperAdminTrigger, flags) => {
					return (
						/**
						 * This is an anti-pattern for passing flags state & disableSuperAdminTrigger.
						 *
						 * We need to pass `flags` and `disableSuperAdminTrigger` as props as we need them accessible in `componentDidMount` function.
						 * If we subscribe to them in the `render` function, we will not be able to access to them in `componentDidMount` function.
						 */
						<Fragment>
							<SuperAdminExpirationTimer
								flags={flags}
								disableSuperAdminTrigger={disableSuperAdminTrigger}
							/>
							<SuperAdminExpirationFlag flags={flags} />
						</Fragment>
					);
				}}
			</DisableSuperAdminMutation>
		);
	};
}

export const SuperAdminExpiration = withAnalyticsEvents()(SuperAdminExpirationComponent);
