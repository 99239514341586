import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { Box, xcss } from '@atlaskit/primitives';

import AkWarningIcon from '@atlaskit/icon/glyph/warning';
import { N700, N800, Y400, Y500 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { BannerStateContainer } from '@confluence/banners';
import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import { getSuperAdminClaim } from '@confluence/super-admin-claim';
import { gridSize } from '@confluence/typography-placeholder';
import { ErrorDisplay } from '@confluence/error-boundary';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { AdminKeyQuery } from './AdminKeyQuery';
import { SuperAdminSatisfactionSurvey } from './SuperAdminSatisfactionSurvey';
import { DisableSuperAdminMutation } from './disable-super-admin/DisableSuperAdminMutationComponent';
import { SuperAdminExpiration } from './super-admin-expiration/SuperAdminExpiration';

const BANNER_NAME = 'SuperAdminBanner';

const bannerWrapperStyles = xcss({
	width: '100%',
});

const BANNER_CONTENTS_HEIGHT = gridSize * 3.5; // 28px when gridSize = 8px
const BANNER_FONT_SIZE = BANNER_CONTENTS_HEIGHT / 2; // 14px when gridSize = 8px
const BANNER_PADDING_SIZE = BANNER_FONT_SIZE - gridSize / 4; // 12px when gridSize = 8px
const BANNER_TOTAL_HEIGHT = BANNER_CONTENTS_HEIGHT + BANNER_PADDING_SIZE * 2; // 52px when gridSize = 8px;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Banner = styled.div({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${BANNER_CONTENTS_HEIGHT}px`,
	backgroundColor: `${token('color.background.warning.bold', Y400)}`,
	color: `${token('color.text.warning.inverse', N700)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${BANNER_FONT_SIZE}px`,
	fontWeight: token('font.weight.regular', '500'),
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
	padding: token('space.150', `${BANNER_PADDING_SIZE}px`),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerText = styled.span({
	padding: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExitLink = styled.a({
	color: 'inherit',
	cursor: 'pointer',
	fontWeight: 'normal',
	marginLeft: token('space.050', '4px'),
	textDecoration: 'underline',
});

const WARNING_ICON = (
	<AkWarningIcon
		label="Warning icon"
		size="medium"
		primaryColor={token('color.icon.warning.inverse', N800)}
		secondaryColor={token('color.icon.warning', Y500)}
	/>
);

export const SuperAdminBannerComponent: FC<{
	bannerState: BannerStateContainer;
}> = ({ bannerState }) => {
	const [isSurveyVisible, setIsSurveyVisible] = useState<boolean>(false);
	const [bannerHeight, setBannerHeight] = useState<number>(BANNER_TOTAL_HEIGHT);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	useEffect(() => {
		const surveyIsVisible = localStorage.getItemAsBoolean(keys.SUPERADMIN_SURVEY_REQUESTED);
		if (surveyIsVisible) {
			setIsSurveyVisible(true);
		}
	}, []);

	/**
	 * Shows the Super Admin Satisfaction Survey if it isn't been shown to the user recently, and sets the
	 * persistent survey state which is needed because exiting Super Admin mode causes a full page reload,
	 * which would cause the normal state data to be lost.
	 * @param {boolean} showImmediately - set to true to show immediately, false to only show after next
	 *    remount (aka, wait until after the page reload so there's no flickering of the survey).
	 */
	const showSurvey = (showImmediately) => {
		const surveyRecentlySubmitted = localStorage.getItemAsBoolean(keys.SUPERADMIN_SURVEY_SUBMITTED);
		if (!surveyRecentlySubmitted) {
			localStorage.setItem(keys.SUPERADMIN_SURVEY_REQUESTED, 'true');
			if (showImmediately) {
				setIsSurveyVisible(true);
			}
		}
	};

	/**
	 * Hides the Super Admin Satisfaction Survey, and clears the persistent survey state
	 */
	const hideSurvey = () => {
		localStorage.removeItem(keys.SUPERADMIN_SURVEY_REQUESTED);
		setIsSurveyVisible(false);
	};

	const fireAdminKeyExitAnalyticsEvent = useCallback(
		() =>
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'link',
					actionSubjectId: 'adminKeyExit',
					source: 'superAdminBanner',
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const fireAdminKeyExitFailedAnalyticsEvent = useCallback(
		() =>
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'failed',
					actionSubject: 'exitAdminKeySession',
					source: 'superAdminBanner',
				},
			}).fire(),
		[createAnalyticsEvent],
	);

	const deleteStoredRestrictedPage = () => localStorage.removeItem(keys.RESTRICTED_PAGE_CONFIRMED);

	const handleMutationCompleteCallback = useCallback(() => {
		showSurvey(false);
		deleteStoredRestrictedPage();
		fireAdminKeyExitAnalyticsEvent();
	}, [fireAdminKeyExitAnalyticsEvent]);

	const handleMutationErrorCallback = () => {
		void Promise.resolve(fireAdminKeyExitFailedAnalyticsEvent());
	};

	const renderBanner = () => {
		// This constant is adapted from the <AKBanner> source code as our custom <Banner> is replacing
		// an <AkBanner>.  This code adds the accesibility attributes of the <AkBanner> so screen
		// readers work the same with the custom banner.
		const accessibilityProps = {
			role: 'alert',
		};

		if (bannerState.getTotalHeight() !== BANNER_TOTAL_HEIGHT) {
			setBannerHeight(BANNER_TOTAL_HEIGHT);
			bannerState.show(BANNER_NAME, bannerHeight);
		}

		return (
			<DisableSuperAdminMutation
				handleMutationCompleteCallback={handleMutationCompleteCallback}
				handleMutationErrorCallback={handleMutationErrorCallback}
			>
				{(disableSuperAdmin) => (
					<Box xcss={bannerWrapperStyles} data-vc="super-admin-banner" {...ssrPlaceholderIdProp}>
						<Banner {...accessibilityProps}>
							{WARNING_ICON}
							<BannerText>
								<FormattedMessage
									id="super-admin.banner.message"
									description="Message in the banner to notify users of super admin mode"
									defaultMessage="You’re using admin key, which gives you temporary access to restricted pages."
								/>
								<ExitLink onClick={disableSuperAdmin}>
									<FormattedMessage
										id="super-admin.banner.exit"
										description="Click to action in banner to allow users to exit super admin mode"
										defaultMessage="Stop using admin key"
									/>
								</ExitLink>
							</BannerText>
						</Banner>
					</Box>
				)}
			</DisableSuperAdminMutation>
		);
	};

	/**
	 * We are using the BannerStateContainer for storing which banners are visible, so we also have
	 * to tell BannerStateContainer when our banner isn't visible any more.  Also, note that this
	 * method is intended to be called during render such that it is expected to return valid JSX,
	 * which is why it must return null.
	 */
	const hideBanner = () => {
		bannerState.hide(BANNER_NAME);
		return null;
	};

	const getBannerDisplay = (loading, error, isSuperAdmin) => {
		if (error) {
			return <ErrorDisplay error={error} />;
		} else if (loading) {
			return null;
		}
		return isSuperAdmin ? renderBanner() : hideBanner();
	};

	const renderBannerQuery = () =>
		getSuperAdminClaim() && (
			<AdminKeyQuery>
				{({ loading, error, isSuperAdmin }) => getBannerDisplay(loading, error, isSuperAdmin)}
			</AdminKeyQuery>
		);

	return (
		<>
			{renderBannerQuery()}
			{isSurveyVisible && <SuperAdminSatisfactionSurvey onDone={hideSurvey} />}
			<SuperAdminExpiration />
		</>
	);
};

export const SuperAdminBanner = () => (
	<Subscribe to={[BannerStateContainer]}>
		{(bannerState: BannerStateContainer) => <SuperAdminBannerComponent bannerState={bannerState} />}
	</Subscribe>
);
