import type { FC } from 'react';
import React from 'react';
import { Mutation } from 'react-apollo';
import { defineMessages, useIntl } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { AtlassianSupportLink } from '@confluence/error-boundary';
import { FlagsStateContainer } from '@confluence/flags';
import { removeSuperAdminClaim } from '@confluence/super-admin-claim';

import { removeAKTimeout } from '../super-admin-expiration/timeoutUtils';

import { DisableSuperAdmin as DisableSuperAdminGraphqlMutation } from './DisableSuperAdminGraphqlMutation.graphql';

const i18n = defineMessages({
	disableSuperAdminErrorTitle: {
		id: 'super-admin.disable.super.admin.error.title',
		defaultMessage: 'Something went wrong',
		description: 'Title for the error if disabling Super Admin fails',
	},
	disableSuperAdminErrorDescription: {
		id: 'super-admin.disable.super.admin.error.description-with-link',
		defaultMessage: 'If this keeps happening, let us know at {atlassianSupportLink}',
		description: 'Description for the error if disabling Super Admin fails',
	},
});

export const DisableSuperAdminMutation: FC<any> = ({
	handleMutationCompleteCallback,
	handleMutationErrorCallback,
	children,
}) => {
	const intl = useIntl();

	/**
	 * Removes the frontend claim for Super Admin, and performs a page reload to exit
	 * Super Admin, reset permissions, and provide a good UX.
	 * @returns {function}
	 */
	const handleMutationComplete = (flags) => (result) => {
		const isSuperAdmin = result?.disableSuperAdmin?.user?.confluence?.roles?.isSuperAdmin;

		if (!isSuperAdmin) {
			removeSuperAdminClaim();
			removeAKTimeout();

			handleMutationCompleteCallback();
			window.location.reload();
		} else {
			handleMutationError(flags)();
		}
	};

	/**
	 * Error handling mechanism for network or server errors.
	 * @param {object} flags
	 * @return {function}
	 */
	const handleMutationError = (flags) => () => {
		flags.showErrorFlag({
			title: intl.formatMessage(i18n.disableSuperAdminErrorTitle),
			description: intl.formatMessage(i18n.disableSuperAdminErrorDescription, {
				atlassianSupportLink: <AtlassianSupportLink showHostname />,
			}),
		});

		handleMutationErrorCallback();
	};

	return (
		<Subscribe to={[FlagsStateContainer]}>
			{(flags) => (
				<Mutation
					mutation={DisableSuperAdminGraphqlMutation}
					onCompleted={handleMutationComplete(flags)}
					onError={handleMutationError(flags)}
				>
					{(disableSuperAdminTrigger) => children(disableSuperAdminTrigger, flags)}
				</Mutation>
			)}
		</Subscribe>
	);
};
