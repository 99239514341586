import gql from 'graphql-tag';

export const DisableSuperAdmin = gql`
	mutation DisableSuperAdmin {
		disableSuperAdmin {
			user {
				id
				confluence {
					roles {
						isSuperAdmin
					}
				}
			}
		}
	}
`;
