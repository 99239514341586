import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl-next';

import { withAnalyticsEvents } from '@atlaskit/analytics-next';

import { dangerouslyCreateSafeString } from '@confluence/safe-string';
import { SatisfactionSurveyFlag } from '@confluence/satisfaction-survey';
import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';

import { SuperAdminSatisfactionSurveyI18n as i18n } from './SuperAdminSatisfactionSurveyI18n';

export class SuperAdminSatisfactionSurveyComponent extends Component {
	static propTypes = {
		intl: PropTypes.object.isRequired,
		createAnalyticsEvent: PropTypes.func.isRequired,
		onDone: PropTypes.func.isRequired,
	};

	state = {
		eventSent: false,
	};

	initializeText = () => {
		// these text values only need to set this data up once, but we need 'intl' to do it so it can't be a static (file) variable
		if (!this.formQuestions) {
			const { intl } = this.props;
			this.title = intl.formatMessage(i18n.superAdminSatisfactionSurveyTitle);
			this.finalTitle = intl.formatMessage(i18n.superAdminSatisfactionSurveyFinalTitle);
			this.finalDescription = intl.formatMessage(i18n.superAdminSatisfactionSurveyFinalDescription);
			this.submitButtonText = intl.formatMessage(i18n.superAdminSatisfactionSurveyButtonSubmit);
			this.closeButtonText = intl.formatMessage(i18n.superAdminSatisfactionSurveyButtonClose);

			this.reactionTitles = [
				intl.formatMessage(i18n.superAdminSatisfactionSurveyReactionTerrible),
				intl.formatMessage(i18n.superAdminSatisfactionSurveyReactionBad),
				intl.formatMessage(i18n.superAdminSatisfactionSurveyReactionOkay),
				intl.formatMessage(i18n.superAdminSatisfactionSurveyReactionGreat),
				intl.formatMessage(i18n.superAdminSatisfactionSurveyReactionAmazing),
			];

			const questionBad = intl.formatMessage(i18n.superAdminSatisfactionSurveyQuestionBad);
			const questionGood = intl.formatMessage(i18n.superAdminSatisfactionSurveyQuestionGood);
			this.formQuestions = [questionBad, questionBad, questionGood, questionGood, questionGood];
		}
	};

	fireAnalyticsEvent = ({ reaction = null, feedback = '', submitted = false }) => {
		const { createAnalyticsEvent } = this.props;
		// use reactionValue == 0 to track when the survey was shown but not submitted
		const reactionValue = submitted && reaction ? reaction.value : 0;
		feedback = submitted ? feedback : '';

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'submitted',
				actionSubject: 'adminKeyFeedbackSurvey',
				attributes: {
					reaction: reactionValue,
					feedback: dangerouslyCreateSafeString(feedback),
				},
				source: 'adminKeyCsatSurvey',
			},
		}).fire();
	};

	markUserAsSubmitted = () => {
		const THREE_DAYS_IN_SECONDS = 3 * 24 * 60 * 60;
		localStorage.setItem(keys.SUPERADMIN_SURVEY_SUBMITTED, 'true', THREE_DAYS_IN_SECONDS);
	};

	handleInteraction = async (data) => {
		const { onDone } = this.props;
		const { eventSent } = this.state;

		/* Send an analytics event:  If the user submitted data, then the "reaction" field is non-zero and we will
        use the data.   If the user did not submit data, then we will set the "reaction" field to zero and we
        can use event as "viewed but dismissed" indicator. */
		if (!eventSent) {
			this.fireAnalyticsEvent(data);
			if (data.submitted) {
				this.markUserAsSubmitted();
			}
			// "await" needed to avoid firing multiple analytic events in rapid succession
			await this.setState({ eventSent: true });
		}

		if (data.dismissed) {
			onDone();
		}
	};

	render() {
		const { eventSent } = this.state;
		this.initializeText();

		return (
			<SatisfactionSurveyFlag
				size="medium"
				title={this.title}
				description=""
				finalTitle={this.finalTitle}
				finalDescription={this.finalDescription}
				reactionTitles={this.reactionTitles}
				formQuestions={this.formQuestions}
				submitButtonText={this.submitButtonText}
				closeButtonText={this.closeButtonText}
				onInteraction={this.handleInteraction}
				isOnFinalStage={eventSent}
				autoHideTime={-1}
				autoHideFinalTime={2000}
			/>
		);
	}
}

export const SuperAdminSatisfactionSurvey = withAnalyticsEvents()(
	injectIntl(SuperAdminSatisfactionSurveyComponent),
);
