import { getLogger } from '@confluence/logger';

const logger = getLogger('super-admin');

const keys = {
	SUPER_ADMIN_TIMEOUT: 'confluence.super.admin.timeout',
};

export const ADMIN_KEY_TOTAL_TIME_TO_LIVE_IN_MINUTES = 10;
export const ADMIN_KEY_TIME_TO_NOTIFY_USER_IN_MINUTES = 9;

export const secondsBetweenNotifyAndExpiry =
	(ADMIN_KEY_TOTAL_TIME_TO_LIVE_IN_MINUTES - ADMIN_KEY_TIME_TO_NOTIFY_USER_IN_MINUTES) * 60;

export const getSecondsBetweenNowAndTime = (time: number): number =>
	Math.round((time - new Date().getTime()) / 1000);

const minutesToSeconds = (a: number): number => a * 60;

export const addMinutesToDate = (date: Date, minutes: number): Date =>
	addSecondsToDate(date, minutesToSeconds(minutes));

export const subtractMinutesFromDate = (date: Date, minutes: number): Date =>
	addSecondsToDate(date, -minutesToSeconds(minutes));

export const addSecondsToDate = (date, seconds): Date => new Date(date.getTime() + seconds * 1000);

type FoldTimeoutParams = {
	beforeNotify: (time: number) => void;
	afterNotify: (time: number) => void;
	afterExpiry: (time: number) => void;
	noTimeout: () => void;
};

/**
 * This function is used to handle the possible cases of AK timeout.
 * the timeout can have possibly 4 values:
 * - current time is before we want to notify about expiry
 * - current time is after we notify the user about expiry
 * - current time is after expiry
 * - timeout is not set (timeout === 0)
 *
 * @param (timeout: number) => ({beforeNotify: () => T, afterNotify: () => T, afterExpiry: () => T, noTimeout: () => T}) => T
 */
export const foldTimeout =
	(timeout: number) =>
	({ beforeNotify, afterNotify, afterExpiry, noTimeout }: FoldTimeoutParams): void => {
		if (timeout) {
			const secondsRemainingForTimeout = getSecondsBetweenNowAndTime(timeout);
			const isTimeoutInFuture = secondsRemainingForTimeout > 0;
			const isTimeAfterNotify = secondsRemainingForTimeout <= secondsBetweenNotifyAndExpiry;

			if (isTimeoutInFuture) {
				return isTimeAfterNotify ? afterNotify(timeout) : beforeNotify(timeout);
			} else {
				return afterExpiry(timeout);
			}
		} else {
			return noTimeout();
		}
	};

export const getAKTimeout = (): number => {
	// The timeout value is read as a string from getItem() - or null if not found
	const timeout = localStorage.getItem(keys.SUPER_ADMIN_TIMEOUT);
	return timeout ? parseInt(timeout) : 0;
};

export const setAKTimeout = (time: number) => {
	try {
		localStorage.setItem(keys.SUPER_ADMIN_TIMEOUT, time.toString());
	} catch (_) {
		logger.error`Unable to set local storage. key = "${keys.SUPER_ADMIN_TIMEOUT}", value = "${time}"`;
	}
};

export const removeAKTimeout = () => {
	localStorage.removeItem(keys.SUPER_ADMIN_TIMEOUT);
};

export const refreshAKTimeout = () => {
	setAKTimeout(addMinutesToDate(new Date(), ADMIN_KEY_TOTAL_TIME_TO_LIVE_IN_MINUTES).getTime());
};
