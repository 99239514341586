import { parse } from 'url';

import React from 'react';
import { FormattedMessage } from 'react-intl-next';

export const ATLASSIAN_SUPPORT_URL = 'https://support.atlassian.com/contact/#/';

type AtlassianSupportLinkProps = {
	showHostname?: boolean;
	children?: React.ReactNode;
};

const getLinkText = (showHostname: AtlassianSupportLinkProps['showHostname']) =>
	showHostname ? (
		parse(ATLASSIAN_SUPPORT_URL).hostname
	) : (
		<FormattedMessage
			id="error-boundary.description.support-link"
			description="The text of the link which will lead the user to the Atlassian Support web site"
			defaultMessage="Atlassian Support"
		/>
	);

export const AtlassianSupportLink: React.FC<AtlassianSupportLinkProps> = ({
	showHostname,
	children,
}) => (
	<a href={ATLASSIAN_SUPPORT_URL} target="_blank">
		{React.Children.count(children) ? children : getLinkText(showHostname)}
	</a>
);
