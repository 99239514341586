import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

/**
 * This component is to provide a decreasing counter for the AK expiration notification flag.
 */

type IntervalId = ReturnType<typeof setInterval>;

type CounterProps = {
	initialCountdown: number;
};

export const Counter: FC<CounterProps> = ({ initialCountdown }) => {
	const [countdown, setCountdown] = useState<number>(initialCountdown);
	const [storedIntervalId, setStoredIntervalId] = useState<IntervalId | null>(null);

	// Our intervalHandler() callback function's scope is frozen at the point in time when it is
	// passed to setInterval() and thus only sees the values of the state variables at that time and
	// doesn't see any changes to them. But each render pass will create an updated version of the
	// intervalHandler() function with the current values of the state variables. Since references
	// are basically const (fixed) even though their values can change, then the setInterval()
	// callback can use a reference so it always sees the latest copy of intervalHandler(). This lets
	// the handler see the up-to-date state values. For more details, see Dan Abramov's blog post on
	// this at https://overreacted.io/making-setinterval-declarative-with-react-hooks/
	const savedHandler = useRef<() => void>(() => {
		/* empty function; easier than having to do undefined checks */
	});

	const startCountdown = (): IntervalId | null => {
		if (initialCountdown > 0) {
			const intervalId = setInterval(() => savedHandler.current(), 1000);
			setStoredIntervalId(intervalId);
			return intervalId;
		}
		// else no counter set, so no interval set either
		return null;
	};

	const stopCountdown = (intervalId: IntervalId | null) => {
		if (intervalId) {
			clearInterval(intervalId);
		}
	};

	const intervalHandler = () => {
		if (countdown > 0) {
			setCountdown(countdown - 1);
		} else {
			stopCountdown(storedIntervalId);
		}
	};

	savedHandler.current = intervalHandler;

	useEffect(
		() => {
			// The cleanup function has the same problem with frozen scope and state variables that the
			// setInterval() callback has (see the big comment above). So by having startCountdown() return
			// the intervalId means we can use that value in the cleanup function and pass it into the
			// stopCountdown() call rather than having needing a constantly-updated reference to read it
			// from the state variable later
			const intervalId = startCountdown();
			return () => {
				stopCountdown(intervalId);
			};
		},
		// We only want to execute the effect exactly once so only one interval is set up, so we
		// intentionally do list any dependencies here
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<FormattedMessage
			id="super-admin.timeout.super.admin.description.counter"
			defaultMessage="{count, plural, =0 {0 seconds} one {1 second} other {# seconds}}"
			description="Time remaining until the Admin Key expiration, part of description in the Admin Key expiry notification flag"
			values={{
				count: countdown,
			}}
		/>
	);
};
